import { useTranslation } from "react-i18next";
import { useGetApps } from "../../hooks/status/useGetApps";
import { useEffect, useState } from "react";
import { makeInitialData } from "../../helper/statusHelper";
import { DisplayStatusList } from "./DisplayStatusList";

type StatusListProps = {
  refreshInterval: any;
  //  pageRefreshCallback: () => void;
}

export const StatusList: React.FC<StatusListProps> = ({ refreshInterval }) => {
  const [appList, setAppList] = useState('');
  const [apps, setApps] = useState([{}]);
  const {

    data: appData,

  } = useGetApps();

  const { t } = useTranslation();

  useEffect(() => {
    if (appData) {
      const { appList, apps } = makeInitialData(appData);
      setAppList(appList);
      setApps(apps);
    }
  }, [appData])




  // const fetchData = async () => {
  //   //setisLoading(!isLoading) //set to true only when the api call is going to happen
  //   //window.location.reload()
  //   // setisLoading(false); //make sure to set it to false so the component is not in constant loading state
  //   //pageRefreshCallback();
  //   // setAppList('');
  //   // setApps([{}])
  // }

  // useEffect(() => {
  //   const comInterval = setInterval(fetchData, Number(refreshInterval) * 60 * 1000); //This will refresh the data at regularIntervals of refreshTime
  //   return () => clearInterval(comInterval) //Clear interval on component unmount to avoid memory leak
  // }, [])


  return (
    <>


      {/* <TableActionBarRow>
       
      </TableActionBarRow> */}

      {appList && appList.length > 0 && <DisplayStatusList
        initialAppList={appList}
        initialApps={apps}
        refreshInterval={refreshInterval} />}

    </>
  );
};
