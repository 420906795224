import React, { useState } from "react";
import Sider from "antd/es/layout/Sider";
import styled, { css } from "styled-components";
import { DesignTokens } from "../../styles/design-tokens";
import { ElevationHelper } from "../../helper/ElevationHelper";

const { spacings, colors, fonts, elevations, dimensions } = DesignTokens;
const { createBoxShadowCSSString } = ElevationHelper;

const StyledSider = styled(Sider)`
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: ${createBoxShadowCSSString({ level: 1 })};
  z-index: ${elevations.level1.zIndex};
  border-right: 1px solid ${colors.gray.light};
  height: 100vh;
  .ant-layout-sider-trigger {
    border-right: 1px solid ${colors.gray.light};
  }

  /* it's necessary to offset the collapse / expand button otherwise the last item will not be clickable */
  & > ul.ant-menu {
    padding-bottom: 48px;
  }
`;

const SiderHeader = styled.div`
  margin-top: ${spacings.large / 2}px;
`;

const SiderContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  justify-content: space-between;
`;

const StyledLogo = styled.img<{ isSiderCollapsed: boolean }>`
  height: 25px;
  margin: 0 ${spacings.xlarge}px;

  ${({ isSiderCollapsed }) =>
    isSiderCollapsed &&
    css`
      margin-left: 24px;
    `}
`;

const StyledApplicationName = styled.div<{ isSiderCollapsed: boolean }>`
  font-family: ${fonts.sansSerifMd};
  margin: 0 ${spacings.xlarge}px ${spacings.xlarge}px ${spacings.xlarge}px;

  ${({ isSiderCollapsed }) =>
    isSiderCollapsed &&
    css`
      font-size: 10px;
      text-align: center;
      margin: 5px 0 38px 0;
    `}
`;

type MainNavigationProps = {
  appName: string;
  logo: string;
  logoCollapsed?: string;
  mainMenu: React.ReactNode;
};

const MainNavigation: React.FC<MainNavigationProps> = ({
  appName,
  logo,
  logoCollapsed,
  mainMenu,
}) => {
  const [isSiderCollapsed, setSiderCollapsed] = useState(false);

  let logoToShow = logo;
  if (logoCollapsed && isSiderCollapsed) {
    logoToShow = logoCollapsed;
  }

  return (
    <StyledSider
      // collapsible
      // collapsed={isSiderCollapsed}
      // onCollapse={setSiderCollapsed}
      // width={dimensions.sider}
      style={{ background: "#FFF" }}
    >
      <SiderHeader>
        <StyledLogo src={logoToShow} isSiderCollapsed={isSiderCollapsed} />
        <StyledApplicationName isSiderCollapsed={isSiderCollapsed}>
          {appName}
        </StyledApplicationName>
      </SiderHeader>
      <SiderContent>{mainMenu}</SiderContent>
    </StyledSider>
  );
};

export { MainNavigation };
