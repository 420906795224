import { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css";
import "./fonts.css";
import { Main } from "./Main";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 0,
    },
  },
});

export const App: React.FC = () => (
  <Suspense fallback={null}>
    <QueryClientProvider client={queryClient}>

      <Main />

    </QueryClientProvider>
  </Suspense>
);
