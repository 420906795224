import { CheckOutlined } from "@ant-design/icons";
export const makeInitialData = (data: any) => {
    let appList = '';
    let apps = [];

    for (let idx = 0; idx < data?.list?.length; idx++) {
        let e = data?.list[idx];
        console.log("outside e ", JSON.stringify(e));
        appList = data?.list?.map(function (e: any) { return e?.id; }).join(',');
        console.log("outside appList ", appList);
        apps[e.id] = {
            "name": e.app_name,
            "hour1_passed": 0,
            "hour1_failed": 0,
            "hour2_passed": 0,
            "hour2_failed": 0,
            "hour4_passed": 0,
            "hour4_failed": 0,
            "hour8_passed": 0,
            "hour8_failed": 0,
            "days7_passed": 0,
            "days7_failed": 0,
            "days30_passed": 0,
            "days30_failed": 0,
            "year_passed": 0,
            "year_failed": 0,
            "year5_passed": 0,
            "year5_failed": 0
        };
    }
    console.log("makeInitialDataApps ", apps)
    return { appList, apps }
}

export const makeHourlyData = (data: any, today: any, apps: any) => {
    //console.log("36 46e ", apps)
    let utcDate = Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), today.getUTCHours(), today.getUTCMinutes(), today.getUTCSeconds());
    let utcLastHour = utcDate - (60 * 60 * 1000);
    let utcLastTwoHour = utcDate - (2 * 60 * 60 * 1000);
    let utcLastFourHours = utcDate - (4 * 60 * 60 * 1000);
    let utcLastEightHours = utcDate - (8 * 60 * 60 * 1000);

    for (let idx = 0; idx < data?.list?.length; idx++) {
        let e = data?.list[idx];
        // console.log("46e ", e)
        // console.log("46e app_id ", e.app_id)
        let target = apps[e.app_id];
        console.log("target ", target)
        if (target) {
            if (new Date(e.time_stamp).getTime() <= utcDate && new Date(e.time_stamp).getTime() >= utcLastHour) {
                target.hour1_passed += e.passed;
                target.hour1_failed += e.failed;
            }
            if (new Date(e.time_stamp).getTime() <= utcDate && new Date(e.time_stamp).getTime() >= utcLastTwoHour) {
                target.hour2_passed += e.passed;
                target.hour2_failed += e.failed;
            }
            if (new Date(e.time_stamp).getTime() <= utcDate && new Date(e.time_stamp).getTime() >= utcLastFourHours) {
                target.hour4_passed += e.passed;
                target.hour4_failed += e.failed;
            }
            if (new Date(e.time_stamp).getTime() <= utcDate && new Date(e.time_stamp).getTime() >= utcLastEightHours) {
                target.hour8_passed += e.passed;
                target.hour8_failed += e.failed;
            }
        }
    }
    // console.log("68apps ", apps)
    return { apps }
}

export const makeDailyData = (data: any, today: any, apps: any) => {
    let utcDate = Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), today.getUTCHours(), today.getUTCMinutes(), today.getUTCSeconds());
    let utcLastSevenDaysDate = utcDate - (7 * 24 * 60 * 60 * 1000);
    let utcLastThirtyDaysDate = utcDate - (30 * 24 * 60 * 60 * 1000);

    for (let idx = 0; idx < data?.list?.length; idx++) {
        let e = data?.list[idx];
        let target = apps[e.app_id];
        if (target) {
            if (new Date(e.time_stamp).getTime() <= utcDate && new Date(e.time_stamp).getTime() >= utcLastSevenDaysDate) {
                target.days7_passed += e.passed;
                target.days7_failed += e.failed;
            }
            if (new Date(e.time_stamp).getTime() <= utcDate && new Date(e.time_stamp).getTime() >= utcLastThirtyDaysDate) {
                target.days30_passed += e.passed;
                target.days30_failed += e.failed;
            }
        }
    }
    return { apps };
}

export const makeMonthlyData = (data: any, today: any, apps: any) => {
    let lastFiveYear = today.getUTCFullYear() - 5;

    for (let idx = 0; idx < data.list.length; idx++) {
        let e = data.list[idx];
        let target = apps[e.app_id];
        if (target) {
            if (new Date(e.time_stamp).getUTCFullYear() == today.getUTCFullYear()) {
                target.year_passed += e.passed;
                target.year_failed += e.failed;
            }
            if (new Date(e.time_stamp).getUTCFullYear() <= today.getUTCFullYear() && new Date(e.time_stamp).getUTCFullYear() >= lastFiveYear) {
                target.year5_passed += e.passed;
                target.year5_failed += e.failed;
            }
        }
    }
    return { apps };
}

export const calculateSuccessRate = (passed: any, failed: any) => {
    //  console.log("calculate ", passed);
    //  console.log("calculate failed ", failed);
    let queryString = getQuery();
    let total = passed + failed;
    let successRate: any = 0;
    if (total == 0) {
        return -1;
    }
    if (queryString.indexOf("show_number=true") >= 0) {
        let rate: any = (passed / total) * 100;
        if (rate && rate != null) {

            successRate = rate.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
        }
    }
    else {
        successRate = Math.round((passed / total) * 100);
    }
    // console.log("calculate successRate ", successRate);
    return successRate;
}
export const getLegendValue = (dataValue: any): string => {
    let legendValue = '';
    let queryString = getQuery();
    if (queryString.indexOf("show_number=true") >= 0) {
        if (dataValue == -1) {
            dataValue = '-';
        }
        else {
            dataValue = dataValue + '%';
        }
        //legendValue = '<span align="center" style="text-align:center; font-size:100%;">' + dataValue + '</span>'
        legendValue = dataValue
    }
    else {
        if (dataValue >= 99) {
            //legendValue = '<span align="center" style="text-align:center; font-size:150%; font-weight:bold; color:green;">&#10004;</span>'
            legendValue = "good"
        }
        else if (dataValue >= 95 && dataValue < 99) {
            //legendValue = '<span align="center" style="text-align:center; font-size:150%; font-weight:bold; color:#FF4500;">&#9888;</span>'
            legendValue = "warning";
        }
        else if (dataValue >= 0 && dataValue < 95) {
            //legendValue = '<span align="center" style="text-align:center; font-size:150%; font-weight:bold; color:red;">&#9747;</span>'
            legendValue = "critical";
        }
        else if (dataValue < 0) {
            // legendValue = '<span align="center" style="text-align:center; font-size:150%; font-weight:bold; color:gray;">&#9900;</span>'
            legendValue = "na"
        }
    }
    return legendValue;
}




export const getQuery = () => {
    let query = window.location.search.substring(0);
    // console.log(query);
    return query;
};

