import { useTranslation } from "react-i18next";

import styled from "styled-components";
import { Button } from "../../common-ui/components/button/Button";
import { ReloadIcon } from "../../common-ui/components/icons/icons";

const StyledApplicationError = styled.div`
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledCenterer = styled.div`
  text-align: center;
`;

export const ApplicationErrorBoundary: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledApplicationError>
      <StyledCenterer>
        <p>{t("errorboundary.message")}</p>
        <Button
          key="reload"
          type="primary"
          icon={<ReloadIcon />}
          onClick={() => {
            window.location.reload();
          }}
        >
          {t("errorboundary.buttonLabel")}
        </Button>
      </StyledCenterer>
    </StyledApplicationError>
  );
};
