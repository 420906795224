import { useTranslation } from "react-i18next";

import Logo from "../../assets/logo.svg";
import BerninaLogo from "../../assets/bernina_logo.svg";
import LogoCollapsed from "../../assets/logo_collapsed.svg";
import { useCurrentProductVariant } from "../../hooks/useCurrentProductVariant";
import { MainMenu } from "./MainMenu";
import { MainNavigation } from "../../common-ui/components/navigation/MainNavigation";

export const StatusMainNavigation: React.FC = () => {
  const { t } = useTranslation();
  const productVariant = useCurrentProductVariant();

  return (
    <MainNavigation
      appName={t(`product.name.${productVariant}`)}
      logo={productVariant == "melco" ? Logo : BerninaLogo}
      logoCollapsed={LogoCollapsed}
      mainMenu={<MainMenu />}
    />
  );
};
