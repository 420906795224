import { fetchData } from "../../helper/api";
import { useCallback } from "react";
import { message } from "antd";

export const useGetDailyStatus = (appList: string) => {

  const fetchDailyStatusData = async () => {

    let response = await fetchData('/analytics/status/daily?app_list=' + appList)

    return response;

  }


  const getDailyStatus = useCallback(async () => {
    try {
      let response = await fetchDailyStatusData();
      return {
        data: response,
        success: true
      }
    }
    catch (e: any) {

      let errorMessage = "Get status daily : " + e?.message
      message.error(errorMessage);
      console.log("Error : Daily Status", e)
      return {
        //data: [],
        success: false
      }
    }
  }, [fetchDailyStatusData])

  return { getDailyStatus }
};
