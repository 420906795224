import { CheckOutlined, CloseOutlined, MinusCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { List } from "antd";
import { useTranslation } from "react-i18next";

export const MainMenu: React.FC = () => {

  const { t } = useTranslation();

  return (
    <>
      <List
        style={{ margin: "26px 0 0 15px", }}

        dataSource={[
          'good',
          'warning',
          'critical',
          'na',

        ]}
        renderItem={(item, index) => (
          <List.Item>

            {item === "good" ?
              <div >
                <CheckOutlined style={{
                  strokeWidth: "50",
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                  "color": "green"
                }} /> {t('main_menu.good')}</div> : ""
            }


            {item === "warning" ? <div >
              <WarningOutlined style={{
                strokeWidth: "50",
                fontSize: "20px",
                textAlign: "center",
                fontWeight: "bold",
                "color": "#E9D502"
              }} /> {t('main_menu.warning')} </div> : ""}



            {item === "critical" ? <div ><CloseOutlined style={{
              strokeWidth: "50",
              fontSize: "20px",
              textAlign: "center",
              fontWeight: "bold",
              "color": "red"
            }} /> {t('main_menu.critical')}</div> : ""}



            {item === "na" ? <>
              <MinusCircleOutlined style={{
                strokeWidth: "50",
                fontSize: "15px",
                textAlign: "center",
                fontWeight: "bold",

              }} /> {t('main_menu.na')} </> : ""}



            {/* {item === "info" ? <><InfoCircleOutlined style={{
              strokeWidth: "50",
              fontSize: "15px",
              textAlign: "center",
              fontWeight: "bold",
              color: "blue"
            }} /> {t('main_menu.info')}</> : ""} */}



          </List.Item>
        )}
      />

    </>
  );
};
