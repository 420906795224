import { useTranslation } from "react-i18next";
import { Table } from "../../common-ui/components/table/Table";

import { useEffect, useState } from "react";
import { calculateSuccessRate, getLegendValue, getQuery, makeDailyData, makeHourlyData, makeMonthlyData } from "../../helper/statusHelper";
import { useGetHourlyStatus } from "../../hooks/status/useGetHourlyStatus";
import { useGetDailyStatus } from "../../hooks/status/useGetDailyStatus";
import { useGetMonthlyStatus } from "../../hooks/status/useGetMonthlyStatus";
import { CheckOutlined, CloseOutlined, MinusCircleOutlined, WarningOutlined } from "@ant-design/icons";
import styled from "styled-components";

type DisplayStatusListProps = {
  initialAppList: any;
  initialApps: any;
  refreshInterval: string;
}

const TableWrapper = styled.div`
  padding:0 40px 0 40px;
  margin:40px 0 40px 0;
`
export const DisplayStatusList: React.FC<DisplayStatusListProps> = ({ refreshInterval,
  initialAppList,
  initialApps }) => {
  // console.log("initialAppList ", initialAppList)
  // console.log("initialApps ", initialApps)
  // const [updatedAppList, setAppList] = useState(initialAppList);
  const [updatedApps, setUpdatedApps] = useState()
  const [displayData, setDisplayData] = useState([]);
  const [loading, setLoading] = useState(true);
  // useEffect(() => { setUpdatedApps(initialApps); }, [initialApps])
  const { getHourlyStatus } = useGetHourlyStatus(initialAppList);
  const { getDailyStatus } = useGetDailyStatus(initialAppList);
  const { getMonthlyStatus } = useGetMonthlyStatus(initialAppList);
  const [hourlyData, setHourlyData] = useState<any>();
  const [monthlyData, setMonthlyData] = useState<any>();
  const [dailyData, setDailyData] = useState<any>();


  useEffect(() => {
    loadData();
  }, [])


  const loadData = async () => {
    const responseHourlyStatus = await getHourlyStatus();
    const { data: hourlyData, success: hourlyDataSuccess } = responseHourlyStatus;
    if (hourlyDataSuccess) {
      setHourlyData(hourlyData)
    }
    const responseDailyStatus = await getDailyStatus();
    const { data: dailyData, success: dailyDataSuccess } = responseDailyStatus;
    if (dailyDataSuccess) {

      setDailyData(dailyData)
    }
    const responseMonthlyStatus = await getMonthlyStatus();
    const { data: monthlyData, success: monthlyDataSuccess } = responseMonthlyStatus;
    if (monthlyDataSuccess) {
      setMonthlyData(monthlyData)
    }
    //  debugger;
  }

  let clrInterval: any;
  useEffect(() => {
    clrInterval = setInterval(loadData, Number(refreshInterval) * 60 * 1000); //This will refresh the data at regularIntervals of refreshTime
    return () => { return clearInterval(clrInterval) }//Clear interval on component unmount to avoid memory leak
  }, [refreshInterval])


  //console.log("hourlyData ", hourlyData)
  const { t } = useTranslation();
  let today = new Date();
  //let utcDate = Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate(), today.getUTCHours(), today.getUTCMinutes(), today.getUTCSeconds());
  let queryString = getQuery();

  useEffect(() => {
    if (hourlyData && dailyData && monthlyData) {

      //  setUpdatedApps(undefined)
      const { apps: updatedHourlyData } = makeHourlyData(hourlyData, today, initialApps);

      const { apps: updatedDailyData } = makeDailyData(dailyData, today, updatedHourlyData);
      // setUpdatedApps(updatedDailyData)
      const { apps: updatedMonthlyData } = makeMonthlyData(monthlyData, today, updatedDailyData);
      console.log("APPS UPDATED ")
      setUpdatedApps(updatedMonthlyData);
    }
  }, [hourlyData, dailyData, monthlyData,])


  useEffect(() => {
    let dataToDisplayInTable: any = [];

    if (updatedApps) {
      for (const [key, e] of Object.entries(updatedApps)) {
        dataToDisplayInTable.push(e)

      }

      setDisplayData((prevState) => prevState ? [...prevState, dataToDisplayInTable] : dataToDisplayInTable);
      setLoading(false);
    }
  }, [updatedApps])





  const getIcon = (v: string) => {
    switch (v) {
      case "good": return <CheckOutlined style={{
        strokeWidth: "50",
        fontSize: "20px",
        textAlign: "center",
        fontWeight: "bold",
        "color": "green"
      }} />

      case "critical": return <CloseOutlined style={{
        strokeWidth: "50",
        fontSize: "20px",
        textAlign: "center",
        fontWeight: "bold",
        "color": "red"
      }} />

      case "warning": return <WarningOutlined style={{
        strokeWidth: "50",
        fontSize: "20px",
        textAlign: "center",
        fontWeight: "bold",
        "color": "#E9D502"
      }} />

      case "na": return <MinusCircleOutlined style={{
        strokeWidth: "50",
        fontSize: "15px",
        textAlign: "center",
        fontWeight: "bold",

      }} />

      default: return <span style={{
        textAlign: "center",
        fontSize: "100%"
      }}
      >
        {v}
      </span>
    }
  }
  let columns = [
    {
      title: t("status_list.table.columns.product_services"),
      key: "name",
      dataIndex: "name",
      width: 170,
    },
    {
      title: t("status_list.table.columns.hour_1"),
      key: "hour_1",
      // width: 110,
      //dataIndex: "hour_1",
      render: (e: any) => {
        let temp = (calculateSuccessRate(e?.hour1_passed, e?.hour1_failed) > 0) ? calculateSuccessRate(e?.hour1_passed, e?.hour1_failed) : calculateSuccessRate(e?.hour2_passed, e?.hour2_failed);

        // console.log("legandValue ", getLegendValue(temp))
        return (<> {getIcon(getLegendValue(temp))}</>)
      }

    },
    {
      title: t("status_list.table.columns.hour_4"),
      key: "hour_4",
      render: (e: any) => {
        let temp = calculateSuccessRate(e.hour4_passed, e.hour4_failed);
        return (<> {getIcon(getLegendValue(temp))}</>)
      }

    },
    {
      title: t("status_list.table.columns.hour_8"),
      key: "hour_8",
      render: (e: any) => {
        let temp = calculateSuccessRate(e.hour8_passed, e.hour8_failed);
        return (<> {getIcon(getLegendValue(temp))}</>)
      }

    },
    {
      title: t("status_list.table.columns.days_7"),
      key: "days_7",
      render: (e: any) => {
        let temp = calculateSuccessRate(e.days7_passed, e.days7_failed);
        return (<> {getIcon(getLegendValue(temp))}</>)
      }

    },
    {
      title: t('status_list.table.columns.days_30'),
      key: "days_30",
      render: (e: any) => {
        // console.log("81Object ", e)
        let temp = calculateSuccessRate(e.days30_passed, e.days30_failed);
        return (<> {getIcon(getLegendValue(temp))}</>)
      }

    },

  ];

  if (queryString.indexOf('include_internal_apps=true') >= 0) {
    columns.push({
      title: t('status_list.table.columns.year'),
      key: "year",
      render: (e: any) => {
        // console.log("81Object ", e)
        //let temp = calculateSuccessRate( e.days30_passed, e.days30_failed);
        let temp = calculateSuccessRate(e.year_passed, e.year_failed);
        return (<> {getIcon(getLegendValue(temp))}</>)
      }

    });
    columns.push({
      title: t('status_list.table.columns.year_5'),
      key: "year_5",
      //dataIndex: "hour_1",
      render: (e: any) => {
        let temp = calculateSuccessRate(e.year5_passed, e.year5_failed);
        return (<> {getIcon(getLegendValue(temp))}</>)
      }

    });
  }
  return (
    <div>

      <TableWrapper>
        <Table
          //  style={{ border: "1px solid red" }}
          sticky
          dataSource={displayData[0]}
          loading={loading}
          columns={columns}
          //  onChange={onTableConfigChange}
          pagination={false}
          rowKey="name"
          noDataMessage={loading && displayData?.length == 0 ? "loading" : t("status_list.empty")}
        />
      </TableWrapper>
    </div>
  );
};
