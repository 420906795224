import { useQuery } from "react-query";
import { fetchData } from "../../helper/api";
import { getQuery } from "../../helper/statusHelper";




export const useGetApps = () => {


  let queryString = getQuery();
  let url = "/analytics/status/apps";
  if (queryString.length > 0) {
    url = url + queryString;
  }

  const query = useQuery(
    ["apps"],
    async () => {

      let response = await fetchData(url)

      return response;
    },
    {
      keepPreviousData: true,
    }
  );

  //const errorComponent = useTableErrorComponent(query.error, query.refetch);

  return {

    ...query,
  };
};
