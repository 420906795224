import { fetchData } from "../../helper/api";
import { useCallback } from "react";
import { message } from "antd";

export const useGetHourlyStatus = (appList: string) => {

  const fetchHourlyStatusData = async () => {

    let response = await fetchData('/analytics/status/hourly?app_list=' + appList)

    return response;

  }


  const getHourlyStatus = useCallback(async () => {
    try {
      let response = await fetchHourlyStatusData();
      return {
        data: response,
        success: true
      }
    }
    catch (e: any) {

      let errorMessage = "Get status hourly : " + e?.message
      message.error(errorMessage);
      console.log("Error : Hourly Status", e)
      return {
        //data: [],
        success: false
      }
    }
  }, [fetchHourlyStatusData])

  return { getHourlyStatus }
};
