import { createRef } from "react";
import { useTranslation } from "react-i18next";
import { StatusMainNavigation } from "./components/navigation/StatusMainNavigation";
import { MainContent } from "./MainContent";
import { ErrorBoundary } from "react-error-boundary";
import { ApplicationErrorBoundary } from "./components/errorboundary/ApplicationErrorBoundary";
import { useCurrentProductVariant } from "./hooks/useCurrentProductVariant";
import { ApplicationLayout } from "./common-ui/layouts/ApplicationLayout";

function ErrorFallback() {
  return <ApplicationErrorBoundary />;
}

export const Main: React.FC = () => {
  const { t } = useTranslation();
  const productVariant = useCurrentProductVariant();

  const contentAreaRef = createRef<HTMLDivElement>();


  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>

      {/* <ScrollToTop contentAreaRef={contentAreaRef} /> */}
      <ApplicationLayout
        mainNavigation={<StatusMainNavigation />}
        footer={t(`footer.copyright.${productVariant}`, {
          copyrightYear: new Date().getFullYear(),
        })}
        contentAreaRef={contentAreaRef}
      >
        <MainContent />
      </ApplicationLayout>

    </ErrorBoundary>
  );

  // TODO: Display loading indicator
  //  return null;
};
