import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const defaultNS = "melco-fusion-web";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en"],
    fallbackLng: "en",
    ns: ["melco-status-web", "melco-common"],
    defaultNS: "melco-status-web",
    debug: true,
    backend: {
      loadPath: "/translations/{{ns}}/{{lng}}.json",
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    returnNull: false,
  });

export default i18n;
