import axios from "axios";

let API_KEY = process.env.REACT_APP_API_KEY;

let BASE_PATH = process.env.REACT_APP_API_BASE_URL;


console.log(API_KEY);
console.log(BASE_PATH);

export const fetchData = async (url: string) => {

    const response = await axios.get(
        BASE_PATH + url,
        { headers: { 'x-api-key': API_KEY } },

    );
    return response.data;
};