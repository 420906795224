import { useTranslation } from "react-i18next";
import { MainContentArea } from "../../common-ui/layouts/MainContentArea";
import { StatusList } from "../../components/status/StatusList";
import { Title } from "../../common-ui/components/typography/Title";
import { RefreshSlider } from "../../components/refreshslider/RefreshSlider";
import { Row } from "../../common-ui/components/grid/Row";
import { Col } from "../../common-ui/components/grid/Col";
import { useEffect, useState } from "react";
import styled from "styled-components";

const TitleWrapper = styled.div`
margin-left: 15px;
margin-top:5px; 

`

const RefreshSliderWrapper = styled.div`
padding:15px;
`;

const HeaderWrapper = styled.div`
padding: 0 40px 0 40px;

`

export const Status: React.FC = () => {
  const { t } = useTranslation();

  const [refreshInterval, setRefreshInterval] = useState("1");
  const [pageRefreshToggle, setPageRefreshToggle] = useState(false);

  useEffect(() => {
    if (!pageRefreshToggle) {
      setPageRefreshToggle(true);
    }
  }, [pageRefreshToggle])
  return (
    <MainContentArea
      header={
        <HeaderWrapper>
          <Row>
            <Col xs={24} md={8} >
              <TitleWrapper>
                <Title level={3}>
                  {`${process.env.REACT_APP_PRODUCT_VARIANT}` == "melco" ? "Melco" : "Bernina"} cloud status
                </Title>
              </TitleWrapper>
            </Col>
            <Col xs={24} md={16}>
              <RefreshSliderWrapper>
                <Row>
                  <Col xs={24} md={4}>
                    <Title style={{ textAlign: "right", position: "absolute", bottom: "10px", }} level={5}>{t('refresh_every')}</Title>
                  </Col>
                  <Col xs={24} md={20} >
                    <RefreshSlider onChange={(v: any) => setRefreshInterval(v)} />
                  </Col>

                </Row>
              </RefreshSliderWrapper>
            </Col>
          </Row>
        </HeaderWrapper>

      }

    >

      {pageRefreshToggle && <StatusList
        refreshInterval={refreshInterval}
      //  pageRefreshCallback={() => setPageRefreshToggle(false)}
      />}
    </MainContentArea >
  );
};
