import { Slider } from "antd"
import type { SliderSingleProps } from 'antd';

type RefreshSliderProps = {
    onChange: (v: any) => void
}
export const RefreshSlider: React.FC<RefreshSliderProps> = ({ onChange }) => {

    const marks: SliderSingleProps['marks'] = {
        1: '1min',
        5: '5min',
        10: '10min',


    };

    return (<>
        <Slider
            min={1}
            max={10}
            step={1}
            included={true}
            marks={marks}
            defaultValue={1}
            onChange={onChange}

        />
    </>)
}