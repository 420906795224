import { fetchData } from "../../helper/api";
import { useCallback } from "react";
import { message } from "antd";

export const useGetMonthlyStatus = (appList: string) => {

  const fetchMonthlyStatusData = async () => {

    let response = await fetchData('/analytics/status/monthly?app_list=' + appList)

    return response;

  }


  const getMonthlyStatus = useCallback(async () => {
    try {
      let response = await fetchMonthlyStatusData();
      return {
        data: response,
        success: true
      }
    }
    catch (e: any) {

      let errorMessage = "Get status monthly : " + e?.message
      message.error(errorMessage);
      console.log("Error : Monthly Status", e)
      return {
        //data: [],
        success: false
      }
    }
  }, [fetchMonthlyStatusData])

  return { getMonthlyStatus }
};
